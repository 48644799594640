(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage'];

    function authInterceptor ($rootScope, $q, $location, $localStorage, $sessionStorage) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            /*jshint camelcase: false */
            config.headers = config.headers || {};
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            var customerId = $localStorage.customerId;
            var contactId = $localStorage.contactId;
            var contactEmail = $localStorage.contactEmail;
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
                if (customerId) config.headers['Customer-ID'] = customerId;
                if (contactId) config.headers['Contact-ID'] = contactId;
                if (contactEmail) config.headers['Contact-Email'] = contactEmail;
            }
            return config;
        }
    }
})();
